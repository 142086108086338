import React, { useEffect } from "react";
// import * as jwt_decode from 'jwt-decode';
// import {jwt_decode} from "jwt-decode";
import { Container, Box } from "@mui/material";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ImageComponent from "./ImageComponent";
import { socialLogin } from "../Apiconfig";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  //   let decodedToken;
  const navigate = useNavigate();
  const initGoogle = () => {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id:
          "766223578897-d2i89sfhil674elhs2l85sd84a0oo84l.apps.googleusercontent.com",
        callback: (response) => {
          console.log(response, "response");
          signInGoogle(response.credential);
        },
      });

      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          shape: "rectangular",
          text: "sign_in_with",
          logo_alignment: "center",
          width: 900,
          height: "standard",
          longtitle: true,
          position: "absolute",
          prompt_parent_id: "customPromptDiv",
          custom_elements: [{ "data-prompt_parent_id": "customPromptDiv" }],
        }
      );

      window.google.accounts.id.prompt();
    } else {
      console.error("Google authentication library not loaded");
    }
  };

  //   const signInWithGoogle = (token) => {
  //     const object = JSON.parse(atob(token.split('.')[1]))
  //     console.log(object);
  //     signInGoogle()
  // const jwtToken = token;
  // decodedToken = jwt_decode(jwtToken);
  // console.log(decodedToken);
  // signInGoogle();
  //   };

  const signInGoogle = async (token) => {
    const object = JSON.parse(atob(token.split(".")[1]));
    console.log(object);
    const response = await axios.post(socialLogin, {
      google_auth_id: object.jti,
      auth_provider: "google",
      device_token: "string",
      name: object.name,
      email: object.email,
      avatar: object.photo || "",
    });
    console.log("Google login response ---->", response);

    if (response.status === 200) {
      toast.success("Login Successfully");
      console.log(response, "resssssssssss");
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      navigate("/dashboard");
    } else {
      toast.success("Login Failed");
      console.log("Error Google Sign-In:", response?.data);
    }
  };

  useEffect(() => {
    initGoogle();
  }, []);

  return (
    <Container
      style={{
        minHeight: "100vh",
        display: "flex",
        minWidth: "100vw",
        backgroundColor: "#010026",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "25px",
        margin: "0px",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: "-20px" }}
      >
        <Grid item>
          <p
            style={{
              color: "white",
              marginBottom: "8px",
              fontFamily: "sans-serif",
              fontSize: "1.5rem",
              fontWeight: "bolder",
            }}
          >
            MindOrbit
          </p>
        </Grid>

        {/* <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClick}
          style={{
            fontSize: '1rem',
            color: 'white',
            backgroundColor: '#010026',
          }}
        >
          Sign In
        </Button>
      </Grid> */}
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ flex: 1 }}
      >
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <div
            style={{
              width: "30vw",
              borderRadius: "50%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "60px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                overflow: "hidden",
                // maxWidth: "200px",
                margin: "auto",
                position: "relative",
                zIndex: 0,
              }}
            >
              <ImageComponent />
            </div>
          </div>
          <Typography
            variant="h5"
            component="div"
            align="center"
            gutterBottom
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item style={{ paddingTop: "2rem" }}>
              <div
                id="buttonDiv"
                style={{ width: "100%", margin: "auto" }}
              ></div>
              <div
                id="customPromptDiv"
                style={{
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                }}
              ></div>
            </Grid>
          </Typography>

          {/* <img
          src="logo.png"
          alt="Your Image"
          style={{
            width: "100%",
             height: "auto",
            display: "block",
            margin: "auto"
          }}
        /> */}

          {/* <div
    style={{
      width: '30vw',
      borderRadius: '50%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '60px',
      overflow: 'hidden',
    }}
  >
    <img
      // ref={imgRef}
      src="logo.gif"
      alt="Your Image"
      style={{
        width: '100%',
      }}
      // onLoad={handleImageLoad}
      loop
    />
  </div> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
