import { Box, Typography } from "@mui/material";
import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";


const Footer = () => {

  const currentYear = new Date().getFullYear();

const navigate = useNavigate();
  return (
    <Box className="footer">
      <img src="/logo.gif" alt="" height="121px" style={{borderRadius:"100%",marginBottom:"5px"}}  />

      <Box className="footerBottom">
        <div className="privacy" onClick={()=>navigate("/privacypolicy")}>Privacy Policy</div>
        <div className="terms" onClick={()=>navigate("/termcondition")}>Terms & Conditions</div>
        <div className="contact" onClick={()=>navigate("/contactus")}>Contact Us</div>
      </Box>
      <Box className="fullBottom">
       
      </Box>
      <Box className="copyRightSection">
        <Typography>

       <span style={{color: "#9999A2"}}>Copyright©{currentYear}. Created with love by</span>  MindOrbit
      </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
