import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import './Built.scss'
import AOS from "aos";
import "aos/dist/aos.css";
const styled = {
  builtHeading:{
    fontSize:"40px"
  }
}
const Built = () => {
   useEffect(() => {
     AOS.init({
       duration: 2000,
       easing: "ease-in-out",
       once: false,
     });
   }, []);

     useEffect(() => {
       AOS.init({
        
       });

     }, []);
  return (
    <Box className="builtContainer">
      <Box className="builtUpContent" data-aos="fade-up">
        <div className="builtHeading">
          Built for Everyone, Designed for ADHD
        </div>
        <div className="builtSubHeading">
          We understand that in today's fast-paced world, staying organized can
          be a challenge. That's why MindOrbit is not just a task management
          tool; it's a lifeline for individuals who experience ADHD. Our
          software is meticulously crafted by ADHD developers who know the
          unique needs of this community. We've designed MindOrbit to help you
          stay focused, reduce distractions, and achieve more, even on your
          busiest days.
        </div>
      </Box>

      <Grid container className="GridfullBox">
        <Grid item xs={12} sm={12} md={6} className="gridsImage">
          <img
            src="Group.png"
            alt="population"
            height="550px"
            width="550px"
            data-aos="flip-left"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className="gridRightBox" >
            <div className="builtHeading" style={styled.builtHeading}>Join the MindOrbit Community</div>
            <div className="builtSubHeading">
              Discover the power of MindOrbit and experience a new level of
              productivity. Whether you're a professional looking to streamline
              your workday or someone navigating the challenges of ADHD,
              MindOrbit is here to support you.
            </div>
            <br />
            <div className="builtSubHeading">
              Try MindOrbit today and take the first step towards a more
              organized, productive, and stress-free life. Learn More about
              MindOrbit and start simplifying your tasks today!
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Built;
