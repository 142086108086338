import React from "react";
import Typewriter from "typewriter-effect";
import "./register.scss";
import Header from "./header/Header";
import AboutUs from "../components/AboutUS/AboutUs";
import Built from "./Built/Built";
import KeyFeatures from "./KeyFeatures/KeyFeatures";
import { Box } from "@mui/material";
import Footer from "./Footer/Footer";
import VideoComponent from "./AboutUS/VideoComponent";

const styled = {
  h2: {
    fontSize: "40px"
  },
  p: {
    fontSize: "20px"
  },

}
const textData = [
  {
    topheading: "MindOrbit",
    firstheading: "Simplify Your Tasks,",
    secondheading: "Stay on Track!",
    text: "Welcome to MindOrbit, your go-to task management solution designed to streamline and organize your daily life. We understand the complexities of modern living, and at MindOrbit, our mission is to help you effortlessly stay on top of your tasks, ensuring you remain productive and in control.",
    img: "/logo.gif",
    contanterImage: "/images/1st.png"
  },
  {
    topheading: "MindOrbit",
    firstheading: "Our Innovative ",
    secondheading: "Approach",
    text: "Voice-Activated Task Creation: With MindOrbit, adding tasks is as simple as speaking. Just use your voice to create tasks in our app, available on all major platforms—no typing or tapping required. Simply talk, and watch your tasks appear instantly.",
    img: "/logo.gif",
    contanterImage: "/images/2nd.png"
  },
  {
    topheading: "MindOrbit",
    firstheading: "Seamless Calendar",
    secondheading: "Integration",
    text: "Whether you prefer Google Calendar or iCalendar, MindOrbit has you covered. Our software seamlessly syncs with your preferred calendar, ensuring your tasks are always where you need them.",
    img: "/logo.gif",
    contanterImage: "/images/as.png"
  },
  {
    topheading: "MindOrbit",
    firstheading: "Effortless Chrome",
    secondheading: "Extension",
    text: "Our Google Chrome extension takes convenience to the next level. Stay on your current web page, click the extension, and create events in your calendar without missing a beat. It's the ultimate tool for multitaskers.",
    img: "/logo.gif",
    // contanterImage:"/images/4th.png",
    type: "extention"
  },
];

const titles1 = {
  title1: "ADHD-Friendly",
  title2: "Built with ADHD in Mind",
  title3: "Enhance Focus and Productivity",
};

const titles = {
  title1: "Key Features",
  title2: "Why Choose MindOrbit?",
  title3: "Explore the Features That Set Us Apart",
};

const checkBoxData1 = [
  {
    title: "  Voice-Activated Task Creation",
    description:
      "With MindOrbit, you can effortlessly create tasks by speaking into our smartphone app or web app. No typingrequired - just speak, and your tasks are instantly added to your to-do list.",
  },
  {
    title: "Seamless Calendar Integration",
    description:
      "We understand that everyone has their preferred calendar software. That's why MindOrbit seamlessly syncs with Google Calendar and iCalendar, allowing you to manage your schedule with ease.",
  },
  {
    title: "Effortless Chrome Extension",
    description:
      "Our Google Chrome extension is a game-changer. Stay on your current web page, click the extension, and create events in your calendar without disrupting your workflow. It's the perfect tool for those who are always on the move.",
  },
];
export default function Register() {
  const checkBoxData = [
    {
      title: "  Voice-Activated Task Creation",
      description:
        "With MindOrbit, you can effortlessly create tasks by speaking into our smartphone app or web app. No typingrequired - just speak, and your tasks are instantly added to your to-do list.",
    },
    {
      title: "Seamless Calendar Integration",
      description:
        "We understand that everyone has their preferred calendar software. That's why MindOrbit seamlessly syncs with Google Calendar and iCalendar, allowing you to manage your schedule with ease.",
    },
    {
      title: "Effortless Chrome Extension",
      description:
        "Our Google Chrome extension is a game-changer. Stay on your current web page, click the extension, and create events in your calendar without disrupting your workflow. It's the perfect tool for those who are always on the move.",
    },
  ];

  const navigateToURL = () => {
    window.open('https://play.google.com/store/games?hl=en_IN&gl=US&pli=1', '_blank');
  };
  const navigateToURL1 = () => {
    window.open('https://www.apple.com/in/app-store/', '_blank');
  };
  return (
    <div className="registerContainer">
      <Header />
      <div className="introWrapper">
        <h1>Welcome to MindOrbit</h1>
        <h2 style={styled.h2}>
          <Typewriter
            options={{
              strings: "Your Personal AI-Powered Task Companion!",
              delay: 80,
              autoStart: true,
              loop: true,
            }}
          />
        </h2>
        <p className="paragraphTag" style={styled.p}>
          Say hello to effortless productivity as our cutting-edge AI technology transforms your spoken thoughts into actionable tasks seamlessly integrated into your schedule. Experience the future of task management: simply speak, and let MindOrbit do the rest. Maximize your efficiency and reclaim your time with our intuitive platform. Get ready to supercharge your productivity journey with MindOrbit!
        </p>
      </div>
      <div className="downloadWrapper">
        <img src="/logo.gif" alt="" />
        <h2 style={styled.h2}>DOWNLOAD NOW</h2>
        <div>
          <img src="/googleplay.png" onClick={navigateToURL} style={{ borderRadius: "0px", cursor: "pointer" }} alt="" />
          <img src="/applestore.png" onClick={navigateToURL1} style={{ borderRadius: "0", cursor: "pointer", marginLeft: "-1rem" }} alt="" />
        </div>
        <VideoComponent />
      </div>
      <div className="aboutusWrapper">
        <div className="header">
          <h2 >ABOUT</h2>
          <h3 >ABOUT US</h3>
        </div>



        {textData.map((data, index) => (
          <AboutUs key={index} textData={data} isTextOnLeft={index % 2 === 0} contanterImage={data?.contanterImage} />
        ))}

        <Built />
        <Box mb={19}>
          <KeyFeatures checkBoxData={checkBoxData} title={titles} />
        </Box>
        <Box pb={5}>
          <KeyFeatures checkBoxData={checkBoxData1} title={titles1} />
        </Box>

      </div>

      <Footer />
    </div>
  );
}
