import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import toast, { Toaster } from 'react-hot-toast'
import { Contactus } from "../Apiconfig";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Styles.css";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/Footer/Footer";

const ContactUs = () => {
  const navigate = useNavigate();
  // const [isTermsandcondition, setTermscondition] = useState([]);
  const [loader, setloader] = useState(false);

  const submitData = async (values) => {
    console.log("valuessss", values);
    try {
      setloader(true);
      const response = await axios({
        method: "POST",
        url: Contactus,
        data: {
          name: values?.fullName,
          email: values?.email,
          mobile_number: values?.phoneNumber,
          message: values?.message,
        },
      });
      console.log("Contat Us -->12", response);
      if (response?.data?.responseCode === 200) {
        toast .success('Successfully Submited');
        setloader(false);
      }
    } catch (error) {
      console.log("error Contact Us---->", error);
      setloader(false);
    }
  };
  // useEffect(() => {
  //   submitData();
  // }, []);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required."),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    phoneNumber: Yup.string()
      .required("Phone number is required.")
      .matches(/^\d{6,16}$/, "Phone number must be between 6 and 16 digits."),
    message: Yup.string().required("Message is required."),
  });

  return (
    <div
      className=""
      style={{
        height: "100vh",
        background: "#010127",
        // paddingTop: "152px",
        // position:"sticky",
        color: "#fff",
      }}
    >
      <Header />
      <Box
        sx={{
          padding: "4% 10% 50px 10%",
          background: "#010127",
          color: "#fff",
          // "@media(max-width:600px)": { padding: "75px 5%" },
          "@media(max-width:2500px)": { padding: "75px 5%" },
          // marginTop:"60px",
          position: "sticky",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              lineHeight: "normal",
              fontSize: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              position: "sticky",
            }}
          >
            <IoIosArrowBack onClick={() => navigate("/")} />
          </Box>
          <Typography
            variant="h1"
            sx={{
              color: "#fff",
              fontFamily: "Open Sans",
              fontSize: "60px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              "@media(max-width:600px)": { fontSize: "40px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              gap: "10px",
              position: "sticky",
            }}
          >
            Contact Us
          </Typography>
          <Box>{/* lkjljl */}</Box>
        </Box>

        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <Box
                sx={{
                  border: "solid 1px",
                  objectFit: "cover",
                  backgroundImage: `url(./images/Ellipse.png)`,
                  height: "150px",
                  padding: "15px",
                  backgroundSize: "cover",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1B2146",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    marginBottom: 1,
                  }}
                >
                  <img
                    src="images/phone.png"
                    alt="phone"
                    height="30px"
                    width="30px"
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: { sm: "18px", md: "24px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: 1,
                    fontFamily: "Open Sans",
                  }}
                >
                  Phone
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "12px", md: "14px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    fontFamily: "Open Sans",
                  }}
                >
                +91-9999525801
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Box
                sx={{
                  border: "solid 1px",
                  objectFit: "cover",
                  backgroundImage: `url(./images/Ellipse.png)`,
                  height: "150px",
                  padding: "15px",
                  backgroundSize: "cover",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1B2146",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    marginBottom: 1,
                  }}
                >
                  <img
                    src="images/email.png"
                    alt="phone"
                    height="25px"
                    width="30px"
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: { sm: "18px", md: "24px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: 1,
                    fontFamily: "Open Sans",
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "12px", md: "14px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    fontFamily: "Open Sans",
                  }}
                >
              connect@mobiloitte.com
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Box
                sx={{
                  border: "solid 1px",
                  objectFit: "cover",
                  backgroundImage: `url(./images/Ellipse.png)`,
                  height: "150px",
                  padding: "15px",
                  backgroundSize: "cover",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1B2146",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    marginBottom: 1,
                  }}
                >
                  <img
                    src="images/location.png"
                    alt="phone"
                    height="30px"
                    width="22px"
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: { sm: "18px", md: "24px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    marginBottom: 1,
                    fontFamily: "Open Sans",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "12px", md: "14px" }, // Adjust font size based on screen width
                    fontWeight: "700",
                    //   flexWrap: "wrap",
                    color: "#fff",
                    textAlign: "left",
                    fontFamily: "Open Sans",
                  }}
                >
                  D-115, Okhla Phase-1, New Delhi
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "0px" }} spacing={12}>
            <Grid item xs={12} sm={12} md={7} sx={{ height: "900px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2004.764944861061!2d77.27555196957653!3d28.527014327250484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce15070323ffb%3A0x25f3ce05e42db7d5!2sSketchDesign.in!5e0!3m2!1sen!2sin!4v1709125959952!5m2!1sen!2sin"
                width="100%"
                height="100%"
                // style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>

            <Grid item xs={12} sm={12} md={5} sx={{ height: "900px" }}>
              <Grid
                sx={{
                  backgroundImage: `url(./images/Ellipse.png)`,
                  width: "100%",
                  height: "650px",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "40px",
                    fontWeight: "700",
                    fontFamily: "Open Sans",
                  }}
                >
                  Contact Us!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "300",
                    fontFamily: "Open Sans",
                  }}
                >
                  Please enter the details for contacting us.
                </Typography>
                <Divider
                  sx={{
                    height: "5px",
                    width: "95px",
                    marginTop: "4px",
                    backgroundColor: "#5A56C3",
                  }}
                />
                <Box
                  sx={{
                    margin: "20px 10px 0px 10px",
                    paddingRight: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Formik
                    initialValues={{
                      fullName: "",
                      email: "",
                      phoneNumber: "",
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }, event) => {
                      // event.preventDefault();
                      submitData(values);
                      console.log("Form values:", values);
                      // Reset form after submission
                      resetForm();
                    }}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box sx={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "open sans",
                              marginBottom: "5px",
                            }}
                          >
                            Full Name
                          </Typography>
                          <div className="form-group">
                            <Field
                              type="text"
                              name="fullName"
                              autocomplete="off"
                              placeholder="Enter full name"
                              value={values.fullName}
                              onChange={handleChange}
                              className={
                                touched.fullName && errors.fullName
                                  ? "error"
                                  : ""
                              }
                              style={{
                                backgroundColor: "#131C44",
                                color: "white",
                                borderRadius: "30px",
                                height: "45px",
                                width: "90%",
                                border: "none",
                                paddingLeft: "15px",
                                // Add other styles as needed
                              }}
                            />
                            <ErrorMessage
                              name="fullName"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </Box>

                        <Box sx={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "open sans",
                              marginBottom: "5px",
                            }}
                          >
                            Email
                          </Typography>
                          <div className="form-group">
                            <Field
                              type="text"
                              name="email"
                              autocomplete="off"
                              placeholder="Enter email address"
                              value={values.email}
                              onChange={handleChange}
                              className={
                                touched.email && errors.email ? "error" : ""
                              }
                              style={{
                                backgroundColor: "#131C44",
                                color: "white",
                                borderRadius: "30px",
                                height: "45px",
                                width: "90%",
                                border: "none",
                                paddingLeft: "15px",
                                // Add other styles as needed
                              }}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </Box>

                        <Box sx={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "open sans",
                              marginBottom: "5px",
                            }}
                          >
                            Phone Number
                          </Typography>
                          <div className="form-group">
                            <Field
                              type="text"
                              name="phoneNumber"
                              autocomplete="off"
                              placeholder="Enter phone number"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              className={
                                touched.phoneNumber && errors.phoneNumber
                                  ? "error"
                                  : ""
                              }
                              style={{
                                backgroundColor: "#131C44",
                                color: "white",
                                borderRadius: "30px",
                                height: "45px",
                                width: "90%",
                                border: "none",
                                paddingLeft: "15px",
                                // Add other styles as needed
                              }}
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </Box>

                        <Box sx={{ padding: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: "600",
                              fontFamily: "open sans",
                              marginBottom: "5px",
                            }}
                          >
                            Message
                          </Typography>
                          <div className="form-group">
                            <textarea
                              type="text"
                              name="message"
                              placeholder="Enter message"
                              value={values.message}
                              onChange={handleChange}
                              className={
                                touched.message && errors.message ? "error" : ""
                              }
                              style={{
                                backgroundColor: "#131C44",
                                color: "white",
                                borderRadius: "30px",
                                height: "120px",
                                width: "90%",
                                border: "none",
                                paddingLeft: "15px",
                                paddingTop: "10px",
                                // Add other styles as needed
                              }}
                              multiline
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </Box>
                        <Box
                          sx={{
                            marginTop: "40px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            sx={{
                              height: "45px",
                              width: "170px",
                              backgroundColor: "#14184F",
                              borderRadius: "300px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              textTransform: "none",
                              fontSize: "15px",
                              fontWeight: "600",

                              color: "white",
                              position: "absolute",
                              border: ".5px solid #3E3EDE",
                            }}
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </div>
  );
};

export default ContactUs;
